import { graphql, useStaticQuery } from "gatsby";

const useProjects = () => {
    const data = useStaticQuery(graphql`
        query {
            allMdx(sort: { fields: [frontmatter___order], order: ASC }) {
                nodes {
                    frontmatter {
                        title
                        slug
                        portfolioImage {
                            sharp: childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return data.allMdx.nodes.map(project => ({
        title: project.frontmatter.title,
        slug: project.frontmatter.slug,
        image: project.frontmatter.portfolioImage,
    }));
};

export default useProjects;
