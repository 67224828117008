import React from "react";
import { css } from "@emotion/core";

import useProjects from "../hooks/use-projects";

import Layout from "../components/layout";
import ProjectPreview from "../components/project-preview";
import { mq } from "../assets/styles";

const PortfolioPage = () => {
    const projects = useProjects();

    return (
        <Layout>
            <h1>Portfolio</h1>
            <section
                css={css`
                    ${mq[1]} {
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: space-between;

                        * + * {
                            margin-top: 0;
                        }

                        * + h2 {
                            margin-top: 1em;
                        }
                    }

                    ${mq[2]} {
                        justify-content: flex-start;
                    }
                `}
            >
                {projects.map(project => (
                    <ProjectPreview key={project.link} project={project} />
                ))}
            </section>
        </Layout>
    );
};

export default PortfolioPage;
