import React from "react";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

import { colors, border, mq } from "../assets/styles";

const ProjectBackgroundImage = styled(BackgroundImage)`
    background-position: center;
    background-size: cover;
    height: 250px;
`;

const ProjectPreview = ({ project }) => (
    <Link
        to={project.slug}
        css={css`
            border: ${border};
            display: block;

            ${mq[1]} {
                width: 47.5%;
                margin-bottom: 5%;
            }

            ${mq[2]} {
                width: 30%;
                margin-right: 5%;

                &:nth-of-type(3n) {
                    margin-right: 0;
                }
            }
        `}
    >
        <ProjectBackgroundImage tag="section" fluid={project.image.sharp.fluid}>
            <h2
                css={css`
                    color: white;
                    font-weight: 400;
                    font-size: 1.5em;
                    margin-top: 1em;
                `}
            >
                <span
                    css={css`
                        background-color: ${colors.blues[1]};
                        padding: 0.25em;
                    `}
                >
                    {project.title}
                </span>
            </h2>
        </ProjectBackgroundImage>
    </Link>
);

export default ProjectPreview;
